import axios from "axios";
import * as constants from "./constants/appConstants";

const instance = axios.create({
  // baseURL:
  //   "http://127.0.0.1:8000/api/auth"
  //   baseURL: "https://quota-admin.dmlabs.in/api/auth",
  baseURL: "https://quota.dmlabs.in/api/auth",
  // baseURL:"https://quota-api.cvbusinesssolutions.com/api/auth/"
  //baseURL:"http://192.168.1.37:8000/api/auth",
//  baseURL: "https://api.quotagames.com/api/auth",
  //   headers: {
  //     Authorization: constants.token ? `Bearer ${constants.token}` : null,
  //     "Content-Type": "application/json",
  //     accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   },
});

instance.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = await `Bearer ${localStorage.getItem(
      "quota-games-token"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    // console.log(
    //   err && err.message
    // );
    if (err && err.response?.status === 500) {
      return { status: 500 };
    }
    if (err && err.message === "Network Error") {
      return { status: 500 };
    }
    const originalRequest = err.config;
    if (err && err.response?.status === 401) {
      loggingOutUser();
    } else if (err.response.status === 403) {
      let res = await axios.post(
        `https://quota.dmlabs.in/api/auth/refresh_jwt_token_admin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "quota-games-token"
            )}`,
          },
        }
      );
      if (res && res.data && res.data.error === false) {
        unAutherized(res.data.data);
        originalRequest.headers = {
          Authorization: ` Bearer ${res.data.data.refresh_token}`,
        };
        return instance(originalRequest);
      } else if (res && res.data && res.data.error === true) {
        loggingOutUser();
      }
    } else {
      return err && err.response && err.response.data
        ? err.response.data
        : { status: 500 };
    }
  }
);

const unAutherized = async (data) => {
  localStorage.setItem("quota-games-token", data.refresh_token);
};

const loggingOutUser = () => {
  if (localStorage.getItem("quota-games-token")) {
    window.location.href = "/login";
    localStorage.removeItem("quota-games-token");
    localStorage.removeItem("quota-games-id");
  }
};

export default instance;
